/**
 * Extracts a query parameter value from a URL.
 * 
 * @param {string} name The name of the parameter to retrieve.
 * @param {string} [url=window.location.href] The URL to parse. Defaults to the current window location.
 * @returns {string|null} The value of the parameter, or null if not found.
 */
export function getParameterByName(name, url = window.location.href) {
    name = name.replace(/[\[\]]/g, '\\$&');
    const regex = new RegExp('[?&]' + name + '(=([^&#]*)|&|#|$)');
    const results = regex.exec(url);
    if (!results) return null;
    if (!results[2]) return '';
    return decodeURIComponent(results[2].replace(/\+/g, ' '));
}
