import React, { useState, forwardRef, useCallback, useContext } from 'react';
import { detectLanguage } from './detectlang.js';
import { AceEditorComponent } from './AceSpaceBro.js';
import { RenderCopyToClipboardButton } from './copyclip.js';
import DataTable from './DataTable';
import { AuthContext } from '../App.js';
import './Dashboard.css';


export function ChatContainer({ isDarkMode, isMobile, chatHistory, setChatHistory, windowWidth, Ampersandposium, reloadData, wait, styles, edit, del, data, apiVisible, context, model, json}, ref) {
  const { vectorVault } = useContext(AuthContext);
  const [chatMessage, setChatMessage] = useState('');
  const [isChatLoading, setChatIsLoading] = useState(false);
  const [isCopied, setIsCopied] = useState(false);
  const [getContext, setGetContext] = useState(context);
  const [nContext, setNContext] = useState(4);
  const [tempMessage, setTempMessage] = useState('');
  const [displayValue, setDisplayValue] = useState('');

  const copyToClipboard = () => {
    setIsCopied(true);
    setTimeout(() => setIsCopied(false), 3000);
  }
  
  const showChatLoadingSpinner = useCallback(() => {
    setChatIsLoading(true);
  }, [setChatIsLoading]);
  
  const hideChatLoadingSpinner = useCallback(() => {
    setChatIsLoading(false);
  }, [setChatIsLoading]);
  
  const appendChatMessage = useCallback((message, isUserMessage) => {
    setChatHistory((prevHistory) => [
      ...prevHistory,
      { message, isUserMessage },
    ]);
  }, [setChatHistory]);

  const removeLastChatMessage = useCallback(() => {
    setChatHistory((prevHistory) => {
      // Check if the last message is a user message
      if (prevHistory.length > 0 && !prevHistory[prevHistory.length - 1].isUserMessage) {
        // Remove the last item from the array if it's not a user message
        const updatedHistory = prevHistory.slice(0, prevHistory.length - 1);
        return updatedHistory;
      }
      // Return the original chat history if the last message is a user message
      return prevHistory;
    });
  }, [setChatHistory]);
  
  function renderChatSegment(segment, index, isUserMessage) {
    const ram = '```';
    if (segment.startsWith(ram) && segment.endsWith(ram)) {
      const code = segment.slice(3, -3);
      const type = detectLanguage(code)
      
      const increaseBy = 1/(windowWidth/500) * 6
      
      return (
        <div key={index} style={{ position: 'relative', width: `10${increaseBy}%`, marginLeft: '-13px', }}>
        <label>{type}</label>
        <AceEditorComponent code={code} type={type} isDarkMode={isDarkMode} />
        {RenderCopyToClipboardButton(code, isDarkMode, isCopied, setIsCopied, copyToClipboard)}
    </div>
      );
    } else {
      return (
        <div key={index} id="chat-history" style={isUserMessage ? styles.userMessage : styles.botMessage}>
          {segment}
        </div>
      );
    }
  }
  

  const sendChatMessage = async () => {
    if (!chatMessage) return;
    appendChatMessage(chatMessage, true);
    showChatLoadingSpinner();
    let pastChatMessage = chatMessage
    let metatag, metatag_prefixes, metatag_suffixes, returnContext;
    setChatMessage('');
    
    if (pastChatMessage.trim().endsWith('**')) {
      metatag = ['item_id'];
      metatag_prefixes = ['\n**Item ID:'];
      metatag_suffixes = ['\n'];
      returnContext = true;
    } else {
      metatag = [];
      metatag_prefixes = [];
      metatag_suffixes = [];
      returnContext = false;
    }
    
    let fullHistory = chatHistory.map((item) => {
      return item.isUserMessage ? `User: ${item.message}` : `Response: ${item.message}`;
    }).join('\n');

    try {
      // Prepare parameters for getChatStream
      const params = {
          vault: Ampersandposium,
          text: pastChatMessage,
          history: fullHistory,
          get_context: getContext,
          n_context: nContext,
          return_context: returnContext,
          model: model,
          metatag: metatag,
          metatag_prefixes: metatag_prefixes,
          metatag_suffixes: metatag_suffixes,
          // Additional parameters if needed
      };

      // Call getChatStream method
      await vectorVault.getChatStream(params, (dataChunk) => {
        console.log(dataChunk);
          // Handle each chunk of data received from the stream
          if (dataChunk === '!END') {
              // End of stream; update chat history
              setTempMessage(tempMessage => {
                  const newChatHistory = [...chatHistory, 
                      { message: pastChatMessage, isUserMessage: true }, 
                      { message: tempMessage, isUserMessage: false }
                  ];
                  localStorage.setItem("chatHistory", JSON.stringify(newChatHistory));
                  setChatHistory(newChatHistory);
                  return '';
              });
              hideChatLoadingSpinner();
          } else {
              // Append incoming data to the temporary message and update the UI
              setTempMessage(tempMessage => {
                  let updatedTempMessage = `${tempMessage}${dataChunk}`;
                  removeLastChatMessage();
                  appendChatMessage(updatedTempMessage, false);
                  return updatedTempMessage;
              });
          }
      });

    } catch (error) {
        // Handle errors by appending the error message to the chat
        appendChatMessage(error.message, false);
        localStorage.setItem("chatHistory", JSON.stringify([...chatHistory, { message: error.message, isUserMessage: false }]));
    }
  };
  
  const clearChat = useCallback(() =>  {
    setChatHistory([])
    localStorage.removeItem("chatHistory");
  }, [setChatHistory]);
  
  const handleTextareaKeydown = (e) => {
    if  (e.key === 'Enter' && (e.ctrlKey || e.metaKey)) {
      e.preventDefault();
      sendChatMessage();
    }
  };
  
  return (
    <>
      <div className="response-container" id="chat-container" style={{ ...styles.chatResponseContainer, paddingTop: "15px", marginRight: "-12px" }}>
        <div style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center', width: '100%' }}>
          <div style={{ display: 'flex', alignItems: 'center', width: '100%', marginBottom: "20px"  }}>
            
            {/* Spacer element */}
            <div style={{ flexGrow: 1 }}></div>

            <button 
                id="clear-chat" 
                className="link-button" 
                onClick={clearChat} 
                style={{ marginTop: "0px", marginRight: "2px" }}
            >
                Clear
            </button>
          </div>
        </div>

        <div style={{ marginTop: "10px" }}></div>
        {chatHistory.flatMap((item, index) => {
            const segments = item.message.split(/(```[\s\S]*?```)/);
            return segments.map((segment, segmentIndex) =>
              renderChatSegment(segment, `${index}-${segmentIndex}`, item.isUserMessage)
            );
          })}
          <div style={{ display: 'flex', alignItems: 'left', margin: "5px" }}>
            <div style={{ display: 'flex', alignItems: 'center' }}>
              <div style={{ ...styles.yourmsgLabel }}>
                <label htmlFor="chat-message">Chat:</label>
              </div>
            </div>
          </div>
        <textarea //
          placeholder={`Type your message here...`}
          id="chat-message"
          rows={isMobile ? "8" : "5" }
          style={{ ...styles.textarea, paddingLeft: "8px"}}
          value={chatMessage}
          onChange={(e) => setChatMessage(e.target.value)}
          onKeyDown={handleTextareaKeydown}
        />
        
        <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'flex-start' }}> 
          <button id="chat-submit" style={{ ...styles.button }} onClick={sendChatMessage}>Send</button>
        </div>
        <div className="loading-spinner" id="loading-spinner" style={{ ...styles.loadingSpinner, display: isChatLoading ? 'block' : 'none', paddingLeft: '20px'  }}>
          <svg viewBox="0 0 50 50" style={styles.loadingSpinnerSvg}>
            <circle cx="25" cy="25" r="20" stroke="#007aff" strokeWidth="5" fill="none" style={styles.loadingSpinnerCircle} />
          </svg>
        </div>
        <div style={{ marginTop: "100px" }}></div>
        {data && <DataTable Ampersandposium={Ampersandposium} reloadData={reloadData} isDarkMode={isDarkMode} wait={wait} styles={styles} edit={edit} del={del} json={json} />}
      </div>
    </>
  );
}

export default forwardRef(ChatContainer);
/** 
magick -background none -size 960x960 xc:black ( xc:darkred -duplicate 1 +append ) xc:gold ( xc:teal -duplicate 2 +append ) -modulate 100,100,"%[fx:rand()*200]" xc:white -scale x1 +append -write mpr:clut +delete radial-gradient: mpr:clut -clut -scale 100x4% -wave "%[fx:rand()*24+24]"x"%[fx:w/ceil(rand()*4+1)]" -extent "%[w]x%[w]" -roll +0+"%[fx:(rand()*w*0.05)+(w*0.51)]" ( +clone -blur 0x4 ) -insert 0 -composite -duplicate "%[fx:floor(rand()*3+3)*2-1]" -set option:rot "%[fx:180/n]" -virtual-pixel tile -virtual-pixel none -distort SRT "%[fx:t*360/n]" +repage -flatten -extent 100x50% ( +clone -rotate 180 ) -append +channel -virtual-pixel none -distort SRT "0.96 %[fx:rand()*360]" ( +clone -flop ) +repage -insert "%[fx:round(rand())]" -background black -flatten -brightness-contrast 20,20 -normalize dragonFire.png
*/