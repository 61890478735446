// App.js
import React, { useState, useEffect, useCallback, createContext, useRef } from 'react';
import { BrowserRouter, Route, Routes } from 'react-router-dom';
import 'bootstrap/dist/css/bootstrap.min.css';
import './App.css';
import Dashboard from './dashboard/Dashboard'; 
import { postMail } from './api/credationStation';
import useTagManager from './hooks/useTagManager';
import { darkStyles, lightStyles } from './styles.js';
import Database from './dashboard/Database.js';
import VectorVault from 'vectorvault';

export const AuthContext = createContext(null);

function App() {
  const user = window.env.USER;
  const [title, setTitle] = useState('');
  const [description, setDescription] = useState('');
  const [wait, setWait] = useState(true); 
  const [deployments, setDeployments] = useState({});
  const vectorVaultRef = useRef(new VectorVault());

  useTagManager();

  useEffect(() => {
      document.title = title;
  }, [title]);

  // Update meta description
  useEffect(() => {
    const metaDescription = document.querySelector('meta[name="description"]');
    if (metaDescription) {
      metaDescription.setAttribute('content', description);
    } else {
      const newMetaDescription = document.createElement('meta');
      newMetaDescription.setAttribute('name', 'description');
      newMetaDescription.setAttribute('content', description);
      document.head.appendChild(newMetaDescription);
    }
  }, [description]);

  const handleCredGet = useCallback(async () => {
    try {
      setWait(true);
      const result = await postMail(user);
      setDeployments(result.deployments);    
      
      const targetDeployment = Object.values(result.deployments).find(d => 
        window.location.pathname.substring(1) === d.FORWARDSLASH
      );
      if (targetDeployment) {
        setTitle(targetDeployment.TITLE);
        setDescription(targetDeployment.DESCRIPTION);
        await vectorVaultRef.current.initializeDeployment(user, targetDeployment.TITLE);
      }
      
      setWait(false);
    } catch (error) {
      console.error('Error submitting email:', error);
    }
  }, [user]);
  
  useEffect(() => {
    if (user) {
      handleCredGet();
    }
  }, [user, handleCredGet]); 

  return (
    <AuthContext.Provider value={{ vectorVault: vectorVaultRef.current }}>
      <div className="App" style={{ display: 'flex', flexDirection: 'column', height: '100vh' }}>
        <BrowserRouter>
          <div className="Content"> 
            <Routes>
              {Object.entries(deployments).map(([key, deployment]) => (
                <Route
                  key={deployment.FORWARDSLASH}
                  path={`/${deployment.FORWARDSLASH}`}
                  element={
                    deployment.CHAT ? (
                      <Dashboard 
                        Ampersandposium={deployment.VAULT}
                        isDarkMode={deployment.DARK}
                        styles={deployment.DARK ? darkStyles : lightStyles}
                        wait={wait}
                        data={deployment.DATA}
                        edit={deployment.EDIT}
                        del={deployment.DELETE}
                        gp4={deployment.GPT4}
                        apiVisible={deployment.APIVISIBLE}
                        model={deployment.MODEL}
                        context={deployment.CONTEXT}
                        description={deployment.DESCRIPTION}
                        title={deployment.TITLE}
                        json={deployment.JSON}
                      />
                    ) : (
                      <Database
                        Ampersandposium={deployment.VAULT}
                        isDarkMode={deployment.DARK}
                        styles={deployment.DARK ? darkStyles : lightStyles}
                        wait={wait}
                        edit={deployment.EDIT}
                        del={deployment.DELETE}
                        description={deployment.DESCRIPTION}
                        title={deployment.TITLE}
                        json={deployment.JSON}
                      />
                    )
                  }
                />
              ))}
            </Routes>
          </div>
        </BrowserRouter> 
        {/* <p>
          <a href="https://vectorvault.io" target="_blank" rel="noopener noreferrer">
            Powered by Vector Vault
          </a>
        </p> */}
      </div> 
    </AuthContext.Provider>
  );
}

export default App;
