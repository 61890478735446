import React from "react";
import { CopyToClipboard } from 'react-copy-to-clipboard';

export function RenderCopyToClipboardButton(code, isDarkMode, isCopied, setIsCopied) {
    return (
      <CopyToClipboard text={code}>
        <button
          style={{
            position: 'absolute',
            top: '30px',
            right: '8px',
            zIndex: 1,
            backgroundColor: isDarkMode ? '#44475a' : '#ced4da',
            color: 'white',
            borderRadius: '4px',
            padding: '4px 12px',
            border: 'none',
            cursor: 'pointer',
            fontSize: '12px',
            boxShadow: '0 2px 4px rgba(0, 0, 0, 0.1)',
            transition: 'all 0.3s',
          }}
          onClick={() => {
            setIsCopied(true);
            setTimeout(() => setIsCopied(false), 3000);
          }}
          onMouseOver={(e) => {
            e.target.style.backgroundColor = isDarkMode ? '#6272a4' : '#adb5bd';
            e.target.style.boxShadow = '0 4px 6px rgba(0, 0, 0, 0.1)';
          }}
          onMouseOut={(e) => {
            e.target.style.backgroundColor = isDarkMode ? '#44475a' : '#ced4da';
            e.target.style.boxShadow = '0 2px 4px rgba(0, 0, 0, 0.1)';
          }}
        >
          {isCopied ? 'Copied!' : 'Copy'}
        </button>
      </CopyToClipboard>
    );
  }

export function RenderCopyToClipboardButtonLightMode(code, isCopied, setIsCopied) {
    return (
      <CopyToClipboard text={code}>
        <button
          style={{
            position: 'absolute',
            top: '30px',
            right: '8px',
            zIndex: 1,
            backgroundColor: '#ced4da',
            color: 'white',
            borderRadius: '4px',
            padding: '4px 12px',
            border: 'none',
            cursor: 'pointer',
            fontSize: '12px',
            boxShadow: '0 2px 4px rgba(0, 0, 0, 0.1)',
            transition: 'all 0.3s',
          }}
          onClick={() => {
            setIsCopied(true);
            setTimeout(() => setIsCopied(false), 3000);
          }}
          onMouseOver={(e) => {
            e.target.style.backgroundColor = '#adb5bd';
            e.target.style.boxShadow = '0 4px 6px rgba(0, 0, 0, 0.1)';
          }}
          onMouseOut={(e) => {
            e.target.style.backgroundColor = '#ced4da';
            e.target.style.boxShadow = '0 2px 4px rgba(0, 0, 0, 0.1)';
          }}
        >
          {isCopied ? 'Copied!' : 'Copy'}
        </button>
      </CopyToClipboard>
    );
  }
