import React, { forwardRef } from 'react';
import DataTable from './Database'
import './Dashboard.css';


export function DataContainer({ isDarkMode, Ampersandposium, reloadData, showDelete, wait, styles, edit, del, json }, ref) {;
  return (
    <>
      <div className="response-container" id="chat-container" style={{ ...styles.chatResponseContainer, paddingTop: "15px", marginRight: "-12px" }}>
        <div style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center', width: '100%' }}>
          <div style={{ display: 'flex', alignItems: 'center', width: '100%', marginBottom: "20px"  }}>
            {/* Spacer element */}
            <div style={{ flexGrow: 1 }}></div>

          </div>
        </div>
        <div style={{ marginTop: '-50px' }}></div>
        <DataTable Ampersandposium={Ampersandposium} reloadData={reloadData} showDelete={showDelete} isDarkMode={isDarkMode} wait={wait} styles={styles} edit={edit} del={del} json={json} />
      </div>
    </>
  );
}

export default forwardRef(DataContainer);