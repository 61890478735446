// credationStation.js

const postMail = async (userEmail) => {
    try {
        const formData = new URLSearchParams();
        formData.append('email', userEmail);

        const response = await fetch("https://register.vectorvault.io/get_frontend_data", {
            method: 'POST',
            headers: {
                "Authorization": 'expected_authorization_code'
            },
            body: formData
        });

        if (!response.ok) {
            const errorBody = await response.text();
            console.error(`HTTP Error: ${response.status}`, errorBody);
            return;
        }

        const result = await response.json();
        return result;
    } catch (error) {
        console.error("There was an error:", error);
        throw error;
    }
};

export { postMail };
